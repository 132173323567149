@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;500;600;700&display=swap');

:root {
    --color-body: #525252;
    --color-bodydark: #EEEDED;
    --color-heading: #272343;
    --color-headingdark: white;

    --color-base: #080202;
    --color-base2: #F4F4F2;
    --color-brand: #F9D949;
    --color-brand2: #ffd000;
    --sidbar-width: 240px;
    --font-base: "Bai Jamjuree";
    --footer-color:#272343;
    --color-techStackBG: #f8f9fa;
}
[data-theme='dark']{
    
    --color-body: #b6cbce;
    --color-heading: #eef3db;
    --color-base: #434242;
    --color-base2: #222222;
    --color-brand: #F9D949;
    --color-brand2: #ffd000;
    --sidbar-width: 240px;
    --font-base: "Bai Jamjuree";
    --footer-color:#EEEDED;
    --color-techStackBG: #545353;
}

body {
    background-color: var(--color-base2);
    color: var(--color-body);
    font-family: var(--font-base), sans-serif;
}

h1,h2,h3,h4,h5,h6 {
    color: var(--color-heading);
    font-weight: 700;
}

a {
    text-decoration: none;
    color: var(--color-body);
    transition: all 0.4s ease;
}

a:hover {
    color: var(--color-brand);
}

img {
    width: 100%;
}

.text-brand {
    color: var(--color-brand);
}

.bg-base {
    background-color: var(--color-base);
    color: var(--color-bodydark);
}

.fullheight {
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 80px;
    padding-bottom: 80px;
    border-bottom: 2px solid rgba(255,255,255, 0.1);
}

.shadow-effect {
    transition: all 0.5s;
}

.shadow-effect:hover {
    box-shadow: -6px 4px 8px 0 var(--color-brand);
}

.iconbox {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    background-color: var(--color-brand);
    color: var(--color-base);
}
.icon{

}
/* NAVBAR */
.navbar {
    background-color: var(--color-base);
}

.navbar .nav-link {
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
}

.navbar .nav-link:hover {
    color: var(--color-brand);
}

.navbar .nav-link.active {
    color: var(--color-brand);
}


/* BTN */
.btn {
    padding: 12px 28px;
    font-weight: 700;
}

.btn-yellow {
    background-color: var(--color-brand);
    border-color: var(--color-brand);
    color: var(--color-base);
   
}

.btn-yellow:hover,
.btn-yellow:focus {
    background-color: var(--color-brand2);
    color: var(--color-base);
    border-color: var(--color-brand2);
}

.underline-animation {
    font-weight: 700;
    position: relative;
}

.underline-animation::after {
    content: "";
    width: 0%;
    height: 2px;
    background-color: var(--color-brand);
    position: absolute;
    left: 0;
    top: 110%;
    transition: all 0.4s;
}

.underline-animation:hover::after {
    width: 100%;
}

/* CARD */
.card-custom .card-custom-image {
    overflow: hidden;
}

.card-custom .card-custom-image img {
    transition: all 0.4s ease;
}

.card-custom:hover .card-custom-image img {
    transform: scale(1.1);
}

/* CONTACT */
#contact .form-control {
    background-color: var(--color-base);
    border-color: var(--color-base);
    color: var(--color-bodydark);
}

#contact .form-control:focus {
    border-color: var(--color-brand);
    box-shadow: none;
}

#contact .form-control::placeholder {
    color: var(--color-bodydark);
    
}

#contact input.form-control {
    height: 44px;
}

/* SOCIAL ICONS */
.social-icons {

}

.social-icons a {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    font-size: 24px;
}
@media (min-width: 992px) {
  .navbar {
      min-height: 100vh;
      width: var(--sidbar-width);
      background: linear-gradient(var(--color-base),var(--color-base)), url(./img/sidebar-img.jpg);
      background-size: cover;
      background-position: center;
  }

  .navbar-brand img {
      border: 8px solid var(--color-brand);
  }

  /* CONTENT WRAPPER */
  #allcontent {
      margin-left: var(--sidbar-width);
  }
}
.techicon-img{
  height: 120px;
  width: 120px;
  margin: 20px;

}
.techicon{
  justify-content: space-evenly;
  margin: 60px;
}
@media (max-width: 692px){
  .techicon-img{
    height: 80px;
    width: 80px;
    margin: 25px;
  }
}

.footer {
  position: relative;
  width: 100%;
  min-height: 100px;
  padding: 20px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.social-icon,
.menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
}

.social-icon__item,
.menu__item {
  list-style: none;
}

.social-icon__link {
  font-size: 2rem;
  color: var(--footer-color);
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
}
.social-icon__link:hover {
  transform: translateY(-10px);
}

.menu__link {
  font-size: 1.2rem;
  color: var(--footer-color);
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
  opacity: 0.75;
  font-weight: 300;
}

.menu__link:hover {
  opacity: 1;
}

.footer p {
  color: var(--footer-color);
  margin: 15px 0 10px 0;
  font-size: 1rem;
  font-weight: 300;
}


.dark-mode{
   padding-top: 6rem;
}
